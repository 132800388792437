import React from "react"
import { graphql } from "gatsby"
import Post from "../views/layouts/Post"
import get from "lodash/get"

const AboutPage = props => {
  const contents = get(
    props,
    "data.aboutJson.modules[0].copy.childMarkdownRemark.html",
    ""
  )
  return (
    <Post location="关于我们">
      <div>
        <div
          dangerouslySetInnerHTML={{
            __html: contents,
          }}
        />
      </div>
    </Post>
  )
}

export default AboutPage

export const pageQuery = graphql`
  query AboutQuery {
    aboutJson: contentfulPage(slug: {eq: "about"}) {
      modules {
        ... on ContentfulPageCopy {
          id
          copy {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`
